<template>
  <div>
    <h1>Service status</h1>
    <div>
      <h3>
        Backend server: {{ status }}
        <span v-if="status == 'ok'">
          <b-badge variant="success">Running</b-badge>
        </span>
        <span v-else-if="status == 'wait'"></span>
        <span v-else>
          <b-badge variant="danger">Offline</b-badge>
        </span>
      </h3>
      <!-- <h4>
        LocalStorage:
        <b-badge variant="success">Found</b-badge>
        <b-badge variant="danger">Empty</b-badge>
      </h4>
      <h3>
        MongoDB:
        <b-badge variant="success">Connected</b-badge>
        <b-badge variant="danger">Connection error</b-badge>
      </h3> -->

      <form action="">
        <div class="form-group">
          <label for="inputBackendIp">Backend IP address:</label>
          <input
            type="text"
            id="inputBackendIp"
            class="form-control"
            placeholder="127.0.0.1"
            v-model="backendIp"
          />
        </div>
        <button type="button" class="btn btn-primary" @click="update">
          Update backend IP
        </button>
        <!-- <div class="form-group">
          <label for="inputMongoPort">MongoDB port</label>
          <input
            type="text"
            id="inputMongoPort"
            class="form-control"
            placeholder="27017 is default"
            v-model="mongoPort"
          />
        </div>
        <div class="form-group">
          <label for="inputMongoDatabase">MongoDB database name</label>
          <input
            type="text"
            class="form-control"
            id="inputMongoDatabase"
            v-model="mongoDbName"
            placeholder="Insert database name"
          />
        </div> -->
      </form>
      <hr />

      <!-- MongoDB connection string:
      <b> mongodb://{{ backendIp }}:{{ mongoPort }}/{{ mongoDbName }} </b> -->
      <table>
        <!-- <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead> -->
        <tbody>
          <tr>
            <td><strong>Backend URL:</strong></td>
            <td class="text-right">
              {{ this.$store.state.backendUrl }}
            </td>
          </tr>
          <tr>
            <td><strong>Version:</strong></td>
            <td class="text-right">
              {{ this.$store.state.appVersion }}
            </td>
          </tr>
          <!-- <tr>
            <td>MongoDB database:</td>
            <td class="text-right">DTS_database</td>
          </tr>
          <tr>
            <td>Connection string:</td>
            <td></td>
          </tr>
          <tr>
            <td>Last data:</td>
            <td class="text-right">2020-08-18 20:00:00</td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      backendIp: 'localhost',
      status: 'wait'
      // backendUrl: 'http://localhost:8084/'
      // mongoDbName: 'DTS',
      // mongoPort: 27017,
      // mongoUrl: ''
    }
  },
  mounted() {
    if (localStorage.backendIp) {
      this.backendIp = localStorage.backendIp
    }
    this.testConnection()
    // if (localStorage.mongoDbName) {
    //   this.mongoDbName = localStorage.mongoDbName
    // }
    // if (localStorage.mongoPort) {
    //   this.mongoPort = localStorage.mongoPort
    // }
  },
  methods: {
    async testConnection() {
      await axios
        .get(this.$store.state.backendUrl + 'hello')
        .then(response => (this.status = response.data))
        .catch(error => {
          this.status = error
        })
      console.log('' + status)
    },
    update() {
      console.log('Changing ip address of backend ' + this.backendIp)
      this.$store.dispatch('setBackUrl')
      this.testConnection()
    }
  },
  watch: {
    backendIp(newValue) {
      localStorage.backendIp = newValue
    }
    // ,
    // mongoDbName(newValue) {
    //   localStorage.mongoDbName = newValue
    // },
    // mongoPort(newValue) {
    //   localStorage.mongoPort = newValue
    // }
  }
}
</script>

<style></style>
