<template>
  <div>
    <h3>
      <slot>Single measurement - latest data {{ plotData[0].name }}</slot>
    </h3>
    <div
      :id="graphIdName"
      :title="graphTitle"
      :legendDescription="graphLegend"
    ></div>
    <!-- Data: {{ plotData }} -->
  </div>
</template>

<script>
export default {
  props: {
    highcharts: {
      type: Object,
      required: true
    },
    graphIdName: {
      type: String,
      required: true
    },
    graphTitle: {
      type: String,
      default: 'Temperature measurements'
    },
    graphLegend: {
      type: String,
      default: 'Temperature [°C]'
    },
    plotData: {
      // Formated as highcharts series for boost
      type: Array,
      required: true
    },
    zoneLimitHigh: {
      type: Number,
      default: 23.45
    },
    zoneLimitLow: {
      type: Number,
      default: -13.5
    }
  },
  data() {
    return {
      realTime: false,
      redrawName: '',
      myGraph: {},
      options: {
        title: {
          text: this.graphTitle
        },

        chart: {
          type: 'spline', //line or spline
          marginTop: 40,
          marginBottom: 100,
          plotBorderWidth: 1,
          zoomType: 'x',
          height: 430 //,         styledMode: true
        },

        boost: {
          seriesThreshold: 1
        },

        plotOptions: {
          series: {
            zones: [
              {
                value: this.zoneLimitLow,
                className: 'zone-0',
                color: '#4640ff'
              },
              {
                value: this.zoneLimitHigh,
                className: 'zone-1'
              },
              {
                className: 'zone-2',
                color: '#ff4040'
              }
            ],
            point: {
              events: {
                click: function(e) {
                  console.log(e.point.category)
                  //TODO emit event for fixedlength setter
                }
              }
            }
          }
        },

        yAxis: {
          title: {
            text: this.graphLegend
          },
          plotBands: [
            {
              from: -1000,
              to: this.zoneLimitLow,
              color: '#dad8fc'
            },
            {
              from: this.zoneLimitLow,
              to: this.zoneLimitHigh,
              color: '#FFFFFF'
            },
            {
              from: this.zoneLimitHigh,
              to: 1000,
              color: '#FFEBEE'
            }
          ]
        },

        xAxis: {
          title: {
            text: 'Distance [m]'
          }
        },

        tooltip: {
          formatter: function() {
            return (
              // this.point.x + ' m, temperature: <b>' + this.point.y + '℃</b>'
              this.point.x + ' m, <b>' + this.point.y.toFixed(3) + '</b>'
            )
          }
        },

        legend: {
          layout: 'horizontal',
          floating: false,
          // backgroundColor: '#FCFFC5',
          y: 25, //fixes overlapping legend with bottom alignment
          align: 'bottom'
        },

        series: this.plotData
      }
    }
  },
  mounted() {
    // console.log('LINEG Mounted data ' + JSON.stringify(this.plotData))
    var start = Date.now()
    // this.plotData[0].data = this.plotData[0].data.slice(0, 2200)
    // console.log('Drawing line graph ' + this.plotData.length)
    // console.log('Drawing line graph ' + this.plotData[0].data.length)
    this.myGraph = this.highcharts.chart(this.graphIdName, this.options)
    var end = Date.now()
    console.log('Time to get mount graphs: ' + (end - start))
  },
  watch: {
    plotData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && this.myGraph.series !== undefined) {
          // var redrawName = new Date()
          // console.log('Changing data watch?' + redrawName)

          this.myGraph.series[0].setData(val[0].data, true)
          this.myGraph.series[0].update({
            // name: redrawName.toUTCString()
            // name: redrawName.toIsoString()
            name: val[0].name
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// @import 'https://code.highcharts.com/css/highcharts.css';

// $zone-cold: #4640ff;
// $zone-ok: #52a854;
// $zone-hot: #ff4040;

// h3 {
//   color: $zone-ok;
//   color: #dad8fc;
// }

// .highcharts-graph.zone-0 {
//   stroke: #4640ff;
// }
// .highcharts-area.zone-0 {
//   fill: #4640ff;
// }
// .highcharts-point.zone-0 {
//   fill: #4640ff;
// }
// .highcharts-graph.zone-1 {
//   stroke: #52a854;
// }
// .highcharts-area.zone-1 {
//   fill: #52a854;
// }
// /*#7cb5ec*/
// .highcharts-point.zone-1 {
//   fill: #52a854;
// }
// .highcharts-graph.zone-2 {
//   stroke: #ff4040;
// }
// .highcharts-area.zone-2 {
//   fill: #ff4040;
// }
// .highcharts-point.zone-2 {
//   fill: #ff4040;
// }
</style>
