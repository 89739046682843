import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue' //just
// import 'bootstrap/dist/css/bootstrap-reboot.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/bootstrap-icons.svg'
// import 'bootstrap'

import App from './App.vue'
import router from './router'
import BaseIcon from '@/components/BaseIcon.vue'
import store from './store'

Vue.component('BaseIcon', BaseIcon)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  created() {
    //initialize store backendUrl
    console.log('initializing store')
    this.$store.dispatch('setBackUrl')
  },
  render: h => h(App)
}).$mount('#app')
