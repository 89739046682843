<template>
  <div>
    <h1>Softwarový analyzátor stavu hybridních kabelů</h1>
    <br />
    <h2>
      Vývoj a testování hybridních kabelů, hermetických kabelových průchodek a
      modulů pro jadernou energetiku
    </h2>
    <h2>
      Development and testing of hybrid cables, hermetic cable bushings and
      modulus for nuclear energetics.
    </h2>
    <p>
      Kód projektu: TK01020178
    </p>
    <p>
      Veřejná soutěž, do které je daný projekt podáván: 1. veřejná soutěž
      Programu na podporu aplikovaného výzkumu, experimentálního vývoje a
      inovací THÉTA Program, do kterého je daný projekt podáván v rámci soutěže:
      TK - Program na podporu aplikovaného výzkumu, experimentálního vývoje a
      inovací THÉTA Podprogram, do kterého je daný projekt podáván v rámci
      programu: Podprogram 2 - Strategické energetické technologie
    </p>
    <p>Název výstupu/výsledku: Softwarový analyzátor stavu hybridních kabelů</p>
    <p>Identifikační číslo: TK01020178-V3</p>
    <br />
    <div class="loga">
      <img src="@/assets/fei-logo.png" />
      <img src="@/assets/theta.jpg" />
      <img src="@/assets/kabex_small.png" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.loga {
  text-align: center;
}
</style>
