<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Dashboard for {{ activeCollection }}</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div>
          <b-dropdown
            id="dropdownCollectionButton"
            text="Data node selector"
            right
            class="m-md-2"
            variant="outline-secondary"
          >
            <div v-for="collection in kabexCollections" :key="collection">
              <b-dropdown-item
                @click="loadCollData(collection)"
                :active="activeCollection === collection"
                >{{ collection }}</b-dropdown-item
              >
            </div>

            <!-- <b-dropdown-item>Second Action</b-dropdown-item>
            <b-dropdown-item>Third Action</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item active>Active action</b-dropdown-item>
            <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
          </b-dropdown>
        </div>
        <!-- Modal -->
        <div>
          <b-button v-b-modal.modal-settings variant="link"
            ><BaseIcon name="settings" width="1.5em" height="1.5em"
          /></b-button>

          <b-modal
            id="modal-settings"
            title="DTS graph settings"
            ok-only
            @ok="handleOk"
          >
            <h5>Internal control values:</h5>
            <!-- <p class="my-4"></p> -->
            <form action="">
              <div class="form-group">
                <label for="inputZLH">Zone limit high</label>
                <input
                  type="number"
                  id="inputZLH"
                  class="form-control"
                  placeholder="23.45"
                  v-model.number="dtsZLH"
                />
              </div>
              <div class="form-group">
                <label for="inputZLL">Zone limit low</label>
                <input
                  type="number"
                  id="inputZLL"
                  class="form-control"
                  placeholder="-13.5"
                  v-model.number="dtsZLL"
                />
              </div>
              <hr />
              <h5>Omit data</h5>
              <div class="form-group">
                <label for="inputTempDataUpperBound"
                  >Upper limit - will not display any higher values.</label
                >
                <input
                  type="number"
                  id="inputTempDataUpperBound"
                  class="form-control"
                  placeholder="380"
                  v-model.number="tempDataUpperBound"
                />
              </div>
              <div class="form-group">
                <label for="inputTempDataLowerBound"
                  >Lower limit - will not display any lower values.</label
                >
                <input
                  type="number"
                  id="inputTempDataLowerBound"
                  class="form-control"
                  placeholder="0"
                  v-model.number="tempDataLowerBound"
                />
              </div>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
    <HighchartsWrapper
      :collData="info"
      :key="hgKey"
      :activeCollection="activeCollection"
      :zoneHigh="dtsZLH"
      :zoneLow="dtsZLL"
      :min="tempDataLowerBound"
      :max="tempDataUpperBound"
    />
    <!-- Axios get: {{ info }} -->
  </div>
</template>

<script>
import HighchartsWrapper from '@/components/HighchartsWrapper.vue'
import axios from 'axios'

export default {
  components: {
    HighchartsWrapper
  },
  data() {
    return {
      hgKey: 0,
      kabexCollections: [],
      activeCollection: 'mar_2020_04_17_17_08_16',
      info: null,
      graphFixedLength: 0,
      dtsZLH: 23.45,
      tempDataUpperBound: 380,
      tempDataLowerBound: 0,
      dtsZLL: -13.5
    }
  },
  methods: {
    async loadCollData(collection) {
      this.activeCollection = collection
      console.log(
        this.$store.state.backendUrl + 'getData?name=' + this.activeCollection
      )
      await axios
        .get(
          this.$store.state.backendUrl + 'getData?name=' + this.activeCollection
        )
        .then(response => (this.info = response.data))
      this.hgKey += 1
      console.log('loadCollData returned ' + this.info.length)
    },
    handleOk() {
      this.hgKey = this.hgKey + 1
    }
  },
  created() {
    if (localStorage.tempDataUpperBound) {
      this.tempDataUpperBound = localStorage.tempDataUpperBound - 0
    }
    if (localStorage.tempDataLowerBound) {
      this.tempDataLowerBound = localStorage.tempDataLowerBound - 0
    }
    if (localStorage.dtsZLH) {
      this.dtsZLH = localStorage.dtsZLH - 0
    }
    if (localStorage.dtsZLL) {
      this.dtsZLL = localStorage.dtsZLL - 0
    }
    axios
      .get(this.$store.state.backendUrl + 'c')
      .then(response => (this.kabexCollections = response.data))
    // Add catch !!!
  },
  watch: {
    tempDataUpperBound(newValue) {
      localStorage.tempDataUpperBound = newValue
    },
    tempDataLowerBound(newValue) {
      localStorage.tempDataLowerBound = newValue
    },
    dtsZLH(newValue) {
      localStorage.dtsZLH = newValue
    },
    dtsZLL(newValue) {
      localStorage.dtsZLL = newValue
    }
  }
}
</script>

<style>
.settingsbutton {
  margin-top: 13px;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-link {
  padding-top: 13px;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  .nav-link {
    color: gray;
  }
}
</style>
