<template>
  <div>
    <h3>{{ wrapperTitle }}</h3>
    <div :id="graphIdName" :title="graphTitle"></div>
  </div>
</template>

<script>
export default {
  props: {
    highcharts: {
      type: Object,
      required: true
    },
    graphIdName: {
      type: String,
      required: true
    },
    graphTitle: {
      type: String,
      default: 'Temperature measurements'
    },
    wrapperTitle: {
      type: String,
      default: 'Heatmap of temperature over distance'
    },
    graphLegend: {
      type: String,
      default: 'Temperature [°C]'
    },
    plotData: {
      type: Array,
      required: true
    },
    hmColSize: {
      //krok, sirka sloupce dle velikosti kroku x osy (x1-x2) * 1,1
      type: Number,
      required: false,
      default: 1.03
    },
    timestamps: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTimestampFrom(id) {
      return this.timestamps[id]
    }
  },
  data() {
    return {
      heatGraph: {},
      heatOptions: {
        // data: this.hdata,
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 100,
          plotBorderWidth: 1,
          zoomType: 'x',
          height: 430
        },
        boost: {
          seriesThreshold: 1,
          debug: { timeRendering: true }
        },
        colorAxis: {
          stops: [
            [0, '#3060cf'],
            [0.5, '#fffbbc'],
            [0.9, '#c4463a'],
            [1, '#c4463a']
            // rainbow like - but hides data
            // [0, 'blue'],
            // [0.25, 'blue'],
            // [0.4, 'cyan'],
            // [0.55, 'green'],
            // [0.8, 'yellow'],
            // [1, 'red']
          ],
          min: 0,
          max: 15,
          startOnTick: false,
          endOnTick: false,
          reversed: false,
          labels: {
            format: '{value}%'
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },
        title: {
          text: this.graphTitle
        },
        xAxis: {
          title: {
            text: 'Distance [m]'
          }
        },
        yAxis: {
          title: {
            text: 'Date & Time'
          },
          categories: this.timestamps
        },
        tooltip: {
          formatter: function() {
            return (
              this.series.name[this.y] +
              ' - ' +
              this.point.x +
              ' m, gain: <b>' +
              this.point.value +
              '%</b>'
            )
          }
        },
        series: [
          {
            turboThreshold: 0,
            boostThreshold: 1000,
            name: this.timestamps,
            borderWidth: 0,
            borderColor: 'black',
            nullColor: '#EFEFEF',
            colsize: this.hmColSize,
            data: this.plotData
          }
        ]
      }
    }
  },
  mounted() {
    // console.log(this.getTimestampFrom(0))
    // console.log('Drawing graph ' + this.plotData.length)
    this.heatGraph = this.highcharts.chart(this.graphIdName, this.heatOptions)
  },
  watch: {
    plotData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && this.heatGraph.series !== undefined) {
          this.heatGraph.series[0].setData(val, true)
        }
      }
    }
  }
}
// tooltip: {
//         formatter: function () {
//             return '<b>' + getPointCategoryName(this.point, 'x') + '</b> sold <br><b>' +
//                 this.point.value + '</b> items on <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
//         }
//     },
</script>

<style lang="scss" scoped></style>
