import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'
import Status from '../views/Status.vue'
import DashExample from '@/views/DashExample.vue'
import Dashboard from '@/views/Dashboard.vue'
import DashboardDsts from '@/views/DashboardDsts.vue'
import DashDstsFreq from '@/views/DashDstsFreq.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'about',
    component: About
  },
  {
    path: '/dsh',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/dshdts',
    name: 'dashboardDts',
    component: Dashboard
  },
  {
    path: '/dshdsts',
    name: 'dashboardDsts',
    component: DashboardDsts
  },
  {
    path: '/dshex',
    name: 'dash-example',
    component: DashExample
  },
  {
    path: '/dshdstsfreq',
    name: 'dashDstsFreq',
    component: DashDstsFreq
  },
  {
    path: '/status',
    name: 'status',
    component: Status
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
