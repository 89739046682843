<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">DSTS Dashboard for {{ activeCollection }}</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div>
          <b-dropdown
            id="dropdownCollectionButton"
            text="Data node selector"
            right
            class="m-md-2"
            variant="outline-secondary"
          >
            <div v-for="collection in kabexCollections" :key="collection">
              <b-dropdown-item
                @click="loadCollData(collection)"
                :active="activeCollection === collection"
                >{{ collection }}</b-dropdown-item
              >
            </div>
          </b-dropdown>
        </div>
        <!-- Modal -->
        <div>
          <b-button v-b-modal.modal-settings variant="link"
            ><BaseIcon name="settings" width="1.5em" height="1.5em"
          /></b-button>

          <b-modal
            id="modal-settings"
            title="DSTS graph settings"
            ok-only
            @ok="handleOk"
          >
            <form action="">
              <h5>Omit data</h5>
              <div class="form-group">
                <label for="inputTempDataUpperBound"
                  >Upper limit - will not display any higher values.</label
                >
                <input
                  type="number"
                  id="inputDstsDataUpperBound"
                  class="form-control"
                  v-model.number="dstsDataUpperBound"
                />
              </div>
              <div class="form-group">
                <label for="inputDstsDataLowerBound"
                  >Lower limit - will not display any lower values.</label
                >
                <input
                  type="number"
                  id="inputDstsDataLowerBound"
                  class="form-control"
                  v-model.number="dstsDataLowerBound"
                />
              </div>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
    <div v-if="hgKey == 'dataReady'">
      <HighchartsWrapperDsts
        :collData="info"
        :key="hgKey"
        :activeCollection="activeCollection"
        :min="dstsDataLowerBound"
        :max="dstsDataUpperBound"
      />
    </div>
    <div v-else>
      <div class="alert alert-primary" role="alert">
        <div class="spinner-border" role="status" />
        <strong> Fetching data from database.</strong>
      </div>
    </div>
    <!-- <h4>Devel info</h4>
    Axios get: {{ info }} -->
  </div>
</template>

<script>
import HighchartsWrapperDsts from '@/components/HighchartsWrapperDsts.vue'
import axios from 'axios'

export default {
  components: {
    HighchartsWrapperDsts
  },
  data() {
    return {
      hgKey: 'fetching',
      kabexCollections: [],
      activeCollection: 'test2_2021_05_12_09_49_22',
      info: null,
      dstsDataUpperBound: 1000,
      dstsDataLowerBound: 0,
      graphFixedLength: 0
    }
  },
  methods: {
    async loadCollData(collection) {
      this.hgKey = 'fetching'
      var start = Date.now()
      this.activeCollection = collection
      console.log(
        this.$store.state.backendUrl +
          'getDstsData?name=' +
          this.activeCollection
      )
      await axios
        .get(
          this.$store.state.backendUrl +
            'getDstsData?name=' +
            this.activeCollection
        )
        .then(response => (this.info = response.data))
      // FILTER DATA
      // if (this.info[0].data.length > 3000) {
      //   console.log('Too big data set, omiting half the data')
      //   this.omitData(this.info)
      // }
      this.hgKey = 'dataReady'
      console.log('loadCollData returned, length ' + this.info.length)
      var end = Date.now()
      console.log('Time to get DSTS data: ' + (end - start))
    },
    handleOk() {
      this.hgKey = this.hgKey + 1
      this.loadCollData(this.activeCollection)
    }
    // omitData(rawData) {
    //   // console.log('before' + rawData[0].data.length)
    //   for (let index = 0; index < rawData.length; index++) {
    //     rawData[index].data = rawData[index].data.filter(
    //       (data, index) => index % 2 == 0
    //     )
    //   }
    //   // console.log('after' + rawData[0].data.length)
    // }
  },
  created() {
    if (localStorage.dstsDataUpperBound) {
      this.dstsDataUpperBound = localStorage.dstsDataUpperBound - 0
    }
    if (localStorage.dstsDataLowerBound) {
      this.dstsDataLowerBound = localStorage.dstsDataLowerBound - 0
    }

    axios
      .get(this.$store.state.backendUrl + 'dstsColls')
      .then(response => (this.kabexCollections = response.data))
    console.log(
      'Created DSTS dash kabexCollections ' +
        JSON.stringify(this.kabexCollections)
    )
    // Add catch !!!
    this.loadCollData(this.activeCollection)
  },
  watch: {
    dstsDataUpperBound(newValue) {
      localStorage.dstsDataUpperBound = newValue
    },
    dstsDataLowerBound(newValue) {
      localStorage.dstsDataLowerBound = newValue
    }
  }
}
</script>

<style>
.settingsbutton {
  margin-top: 13px;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  .nav-link {
    color: gray;
  }
}
</style>
