<template>
  <div>
    <h3>Fixed distance temperature for {{ targetDistance }} m</h3>
    <div :id="graphIdName" :title="graphTitle"></div>
    <div>
      <label for="fixedLengthRange" class="form-label">Fix distance </label>
      <input
        type="range"
        class="form-range"
        min="0"
        step="0.5"
        id="fixedLengthRange"
        v-model="targetDistance"
        :max="maxDistance"
        @mouseup="loadFixedDataFromDb"
        @keyup="loadFixedDataFromDb"
      />
      Selected distance: <strong> {{ targetDistance }}m </strong>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    highcharts: {
      type: Object,
      required: true
    },
    graphIdName: {
      type: String,
      required: true
    },
    graphTitle: {
      type: String,
      default: 'Temperature progression'
    },
    plotData: {
      type: Array,
      required: true
    },
    activeCollection: {
      type: String,
      required: true
    },
    maxDistance: {
      type: Number,
      default: 400
    },
    zoneLimitHigh: {
      type: Number,
      default: 24.35
    },
    zoneLimitLow: {
      type: Number,
      default: -13.5
    }
  },
  data() {
    return {
      dbData: [],
      fixedPlotData: [],
      targetDistance: 0
    }
  },
  mounted() {
    if (localStorage.graphFixedLength) {
      this.targetDistance = localStorage.graphFixedLength
    } else {
      console.log('Cannot load fixed length from local memory, default to 0.')
    }
    this.myTempChart = this.highcharts.chart(this.graphIdName, {
      title: {
        text: this.graphTitle
      },

      chart: {
        type: 'spline', //line or spline
        marginTop: 40,
        marginBottom: 100,
        plotBorderWidth: 1,
        zoomType: 'x',
        height: 430
      },

      yAxis: {
        title: {
          text: 'Temperature [°C]'
        },
        plotBands: [
          {
            from: -1000,
            to: this.zoneLimitLow,
            color: '#dad8fc'
          },
          {
            from: this.zoneLimitLow,
            to: this.zoneLimitHigh,
            color: '#FFFFFF'
          },
          {
            from: this.zoneLimitHigh,
            to: 1000,
            color: '#FFEBEE'
          }
        ]
      },

      xAxis: {
        title: {
          text: 'Date'
        },
        reversed: true,
        type: 'category'
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      plotOptions: {
        series: {
          zones: [
            {
              value: this.zoneLimitLow,
              className: 'zone-0',
              color: '#4640ff'
            },
            {
              value: this.zoneLimitHigh,
              className: 'zone-1'
            },
            {
              className: 'zone-2',
              color: '#ff4040'
            }
          ]
        }
      },

      series: [
        {
          name: 'TMP m',
          data: [
            // funkcni
            // { x: 12, y: 100 },
            // { name: 'AAA', y: 120 },
            // { x: 20, y: 130 }

            { name: '2020-07-07T09:48:54.000Z', y: 100 },
            { name: '2020-07-08T09:48:54.000Z', y: 120 },
            { name: '2020-07-09T09:48:54.000Z', y: 130 }

            // ['2020-07-07T09:48:54.000Z', 100],
            // ['2020-07-08T09:48:54.000Z', 120],
            // ['2020-07-09T09:48:54.000Z', 130]

            //              nefunkcni! musi mit name, pokud je to category
            // { x: '2020', y: 15 },
            // { x: '2021', y: 16 },
            // { x: '2022', y: 25 }
          ]
        }
      ]
    })
  },
  watch: {
    targetDistance(newValue) {
      localStorage.graphFixedLength = newValue
    }
  },
  created() {
    this.loadFixedDataFromDb()
  },
  methods: {
    async loadFixedDataFromDb() {
      await axios
        .get(
          this.$store.state.backendUrl +
            'getTempForLength?name=' +
            this.activeCollection +
            '&length=' +
            this.targetDistance
        )
        .then(response => (this.dbData = response.data))
      console.log(
        'get GFLT with ' +
          this.activeCollection +
          ', ' +
          this.targetDistance +
          ' returned ' +
          this.dbData.length
      )
      //transform dbData
      console.log('Calling update for FLTG')
      this.fixedPlotData = this.dbData.map(mereni => {
        return {
          name: new Date(mereni.timestamp).toLocaleString(),
          y: mereni.data[0].temp
        }
      })

      // console.log('Updating graph data series' + this.fixedPlotData)
      this.updateTempGraph()
      // console.log(this.myTempChart.series[0])
    },
    updateTempGraph() {
      this.myTempChart.series[0].setName(this.targetDistance + ' m', true)
      this.myTempChart.series[0].setData(this.fixedPlotData, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-range {
  width: 50%;
}
.form-label {
  padding: 0.5em 0.5em 0.5em 2em;
}
</style>
