<template>
  <div class="icon-wrapper" v-html="svg"></div>
</template>

// https://feathericons.com/
<script>
import feather from 'feather-icons'
export default {
  props: {
    name: {
      type: String,
      default: 'home',
      required: true
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  },
  computed: {
    svg() {
      return feather.icons[this.name].toSvg({
        class: 'icon',
        width: this.width,
        height: this.height
      })
    }
  }
}
</script>

<style>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  /* color: rgba(0, 0, 0, 0.4); */
  font-size: 1rem;
  font-weight: 600;
}
.icon {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  margin-right: 6px;
}
</style>
