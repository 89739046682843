<template>
  <div>
    <h2>Highchart EXAMPLE component wrapper</h2>
    <div class="form-check">
      <div class="custom-control custom-switch">
        <input
          class="custom-control-input"
          type="checkbox"
          id="rtCheck"
          v-model="rtChecked"
        />
        <label class="custom-control-label" for="rtCheck"
          ><b>Realtime</b> - Update graphs every {{ rtTimer / 1000 }}s, if
          supported.</label
        >
      </div>
      <br />
    </div>
    <div class="row">
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            graphIdName="lineChartSingle"
            :plotData="singleLineChartSeries"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            graphIdName="lineChartMulti"
            :plotData="lineChartSeries"
          >
            Multi-measurements graph
          </LineGraph>
        </figure>
      </div>
    </div>
    <div class="row">
      <!-- <h3>RealDataTest</h3> -->
      <div class="col-md">
        <figure class="figure">
          <HeatmapGraph
            :highcharts="highcharts"
            graphIdName="heatmapGraphReal"
            :plotData="tmpp"
            :timestamps="timestamps"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraphFixedLength
            :highcharts="highcharts"
            graphIdName="lineChartFixedLength"
            :plotData="singleLineChartSeries"
            :activeCollection="activeCollection"
            key="singleLineChartSeries"
          />
        </figure>
      </div>

      <!-- <h3>HM</h3> -->
      <!-- <div class="col-md">
        <figure class="figure">
          <HeatmapGraph
            :highcharts="highcharts"
            graphIdName="heatmapGraph"
            :plotData="hdata"
          />
        </figure>
      </div> -->
    </div>
    <div class="row">
      <!-- <div class="col-md">
        <figure class="figure">
          <SimpleGraph :highcharts="highcharts" graphIdName="simpleGraph" />
        </figure>
      </div> -->
      Was simple graph
    </div>
  </div>
</template>

<script>
import highcharts from 'highcharts'
import hdata from 'highcharts/modules/data'
import heatmap from 'highcharts/modules/heatmap'
import highchartsMore from 'highcharts/highcharts-more'
import hexporting from 'highcharts/modules/exporting'
import hboost from 'highcharts/modules/boost'
import hexportData from 'highcharts/modules/export-data'
hdata(highcharts)
heatmap(highcharts)
highchartsMore(highcharts)
hexporting(highcharts)
hboost(highcharts)
hexportData(highcharts)
// import SimpleGraph from '@/components/SimpleGraph.vue'
import HeatmapGraph from '@/components/HeatmapGraph.vue'
import LineGraph from '@/components/LineGraph.vue'
import LineGraphFixedLength from '@/components/LineGraphFixedLength.vue'

import tmpData from '@/assets/testAll' //tmpData - devel

export default {
  props: {
    collData: {
      type: Array,
      default: null
    },
    activeCollection: {
      type: String,
      required: true,
      default: 'Test data view'
    }
  },
  components: {
    // SimpleGraph,
    HeatmapGraph,
    LineGraph,
    LineGraphFixedLength
  },
  data() {
    return {
      tmpData: tmpData,
      parsed: [],
      tmpp: [],
      tempLine: [],
      lineChartSeries: [],
      singleLineChartSeries: [],
      tempChartSeries: [],
      highcharts,
      //Mock data for development
      hdata: [
        {
          x: 0.0,
          y: 1,
          value: 15.0
        },
        {
          x: 0.3,
          y: 1,
          value: 15.2
        },
        {
          x: 0.6,
          y: 1,
          value: 15.5
        },
        {
          x: 0.9,
          y: 1,
          value: 15.7
        },
        {
          x: 1.2,
          y: 1,
          value: 17.3
        },
        {
          x: 1.5,
          y: 1,
          value: 26.0
        },
        {
          x: 1.8,
          y: 1,
          value: 37.0
        },
        {
          x: 2.1,
          y: 1,
          value: 29.8
        },
        {
          x: 2.4,
          y: 1,
          value: 24.3
        },
        {
          x: 2.7,
          y: 1,
          value: 21.0
        },
        {
          x: 3.0,
          y: 1,
          value: 18.0
        },
        {
          x: 0.0,
          y: 2,
          value: 5.0
        },
        {
          x: 0.3,
          y: 2,
          value: 6.2
        },
        {
          x: 0.6,
          y: 2,
          value: 7.5
        },
        {
          x: 0.9,
          y: 2,
          value: 8.7
        },
        {
          x: 1.2,
          y: 2,
          value: 9.3
        },
        {
          x: 1.5,
          y: 2,
          value: 15.0
        },
        {
          x: 1.8,
          y: 2,
          value: 25.0
        },
        {
          x: 2.1,
          y: 2,
          value: 18.8
        },
        {
          x: 2.4,
          y: 2,
          value: 13.3
        },
        {
          x: 2.7,
          y: 2,
          value: 7.0
        },
        {
          x: 3.0,
          y: 2,
          value: -2.0
        },
        {
          x: 0.0,
          y: 3,
          value: -5.0
        },
        {
          x: 0.3,
          y: 3,
          value: -3.2
        },
        {
          x: 0.6,
          y: 3,
          value: -2.5
        },
        {
          x: 0.9,
          y: 3,
          value: -1.3
        },
        {
          x: 1.2,
          y: 3,
          value: -0.7
        },
        {
          x: 1.5,
          y: 3,
          value: 5.0
        },
        {
          x: 1.8,
          y: 3,
          value: 15.0
        },
        {
          x: 2.1,
          y: 3,
          value: 8.8
        },
        {
          x: 2.4,
          y: 3,
          value: 3.3
        },
        {
          x: 2.7,
          y: 3,
          value: -3.0
        },
        {
          x: 3.0,
          y: 3,
          value: -12.0
        }
      ],
      timestamps: [],
      rtChecked: false,
      rtTimer: 5000
    }
  },
  watch: {
    //Asi neni potreba, reload klicem
    collData: {
      immediate: true,
      deep: true,
      handler() {
        console.log('Changing data? ')
        if (this.collData != null) {
          this.tmpData = this.collData
          console.log('collData' + this.tmpData)
        }
      }
    }
  },
  created() {
    // console.log('Created called')
    console.log('Colldata set tmpData:' + (this.collData != null))
    this.updateGraphs()
    // this.lineChartSeries.push(tmp)
    // )
    // Multi line graph - same as single, but more data, resp. series containg objects, with name and data
  },
  mounted() {
    // this.singleLineChartSeries.push(this.updateGraphs())
    // this.singleLineChartSeries[1].name = 'asdf'
    this.realTimer = setInterval(() => {
      if (this.rtChecked == true) {
        console.log('checked realtime')
        // this.updateChart()
        this.generateRandData()
      }
      console.log('Tick')
    }, this.rtTimer)
  },
  beforeDestroy() {
    clearInterval(this.realTimer)
  },
  methods: {
    generateRandData() {
      //   var pole = []
      //   for (var i = 0; i < 500; i++) {
      //     pole.push(Math.random() + 0.01 * i)
      //   }
      //   console.log('genarator' + pole)
      this.singleLineChartSeries[0].name = new Date().toLocaleString()
      this.singleLineChartSeries[0].data.map(row => {
        row[1] = Math.random() * 5 + 21
      })
      console.log('gen' + this.singleLineChartSeries[0].name)
    },
    updateGraphs() {
      this.parsed = this.tmpData.map((mereni, index) => {
        return {
          rowData: mereni.data.map(zaznam => {
            return {
              x: zaznam.length,
              y: index, //mereni._id
              value: zaznam.temp,
              datetime: mereni.timestamp
                .replace('T', ' ')
                .replace('Z', '')
                .replace(/\.\d\d\d/, '')
            }
          })
        }
      })
      for (var i in this.tmpData) {
        this.timestamps.push(
          this.tmpData[i].timestamp
            .replace('T', ' ')
            .replace('Z', '')
            .replace(/\.\d\d\d/, '')
        )
      }
      // this.tmpp.push(...this.parsed[0].rowData)
      for (var idx in this.parsed) {
        this.tmpp.push(...this.parsed[idx].rowData)
      }
      // this.tmpp.splice(442, 2180)
      // this.hdata.splice(0, 22)

      // line chart
      this.hdata.splice(11, 22)
      // this.tempLine = this.parsed[0].rowData.map(row => {
      //   return {
      //     x: row.x,
      //     y: row.value,
      //     name: row.y
      //   }
      // })
      // this.lineChartSeries.push(
      // var name= this.parsed[0].rowData.y,
      this.lineChartSeries = this.parsed.map(row => {
        return {
          // name: row.rowData[0].y,
          // Or show only date - depending on aggregation
          name: row.rowData[0].datetime,
          data: row.rowData.map(row => [row.x, row.value])
        }
      })
      this.singleLineChartSeries.push(this.lineChartSeries[0])
    }
  }
}
</script>

<style lang="scss" scoped>
.figure {
  border: 1px #cccccc solid;
  padding: 4px;
  width: 100%;
  height: 550px;
}
.h3 {
  padding-left: 0.5em;
}
.custom-control-label {
  font-size: 1.1em;
}

// obsolete
#highChartsContainer {
  width: 100%;
  height: 400px;
  background: teal;
}
</style>
