<template>
  <div>
    <!-- <h2>Highchart component wrapper</h2> -->
    <div class="d-flex flex-row">
      <div class="custom-control custom-switch">
        <input
          class="custom-control-input"
          type="checkbox"
          id="rtCheck"
          v-model="rtChecked"
        />
        <label class="custom-control-label" for="rtCheck"
          ><b>Realtime</b> - Update graphs every {{ rtTimer / 1000 }}s, if
          supported.</label
        >
      </div>
    </div>
    <div class="d-flex flex-row">
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            graphIdName="lineChartSingle"
            :plotData="singleLineChartSeries"
            :zoneLimitLow="zoneLow"
            :zoneLimitHigh="zoneHigh"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            graphIdName="lineChartMulti"
            :plotData="lineChartSeries"
            :zoneLimitLow="zoneLow"
            :zoneLimitHigh="zoneHigh"
          >
            Multi-measurements graph
          </LineGraph>
        </figure>
      </div>
    </div>
    <div class="d-flex flex-row">
      <!-- <h3>RealDataTest</h3> -->
      <div class="col-md">
        <figure class="figure">
          <HeatmapGraph
            :highcharts="highcharts"
            graphIdName="heatmapGraphReal"
            :plotData="heatPlotData"
            :timestamps="timestamps"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraphFixedLength
            :highcharts="highcharts"
            graphIdName="lineChartFixedLength"
            :plotData="singleLineChartSeries"
            :activeCollection="activeCollection"
            :zoneLimitLow="zoneLow"
            :zoneLimitHigh="zoneHigh"
          />
        </figure>
      </div>

      <!-- <h3>HM</h3> -->
      <!-- <div class="col-md">
        <figure class="figure">
          <HeatmapGraph
            :highcharts="highcharts"
            graphIdName="heatmapGraph"
            :plotData="hdata"
          />
        </figure>
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col-md">
        <figure class="figure">
          <SimpleGraph :highcharts="highcharts" graphIdName="simpleGraph" />
        </figure>
      </div>
    </div> -->
  </div>
</template>

<script>
import highcharts from 'highcharts'
import hdata from 'highcharts/modules/data'
import heatmap from 'highcharts/modules/heatmap'
import highchartsMore from 'highcharts/highcharts-more'
import hexporting from 'highcharts/modules/exporting'
import hboost from 'highcharts/modules/boost'
import hexportData from 'highcharts/modules/export-data'
hdata(highcharts)
heatmap(highcharts)
highchartsMore(highcharts)
hexporting(highcharts)
hboost(highcharts)
hexportData(highcharts)
// import SimpleGraph from '@/components/SimpleGraph.vue'
import HeatmapGraph from '@/components/HeatmapGraph.vue'
import LineGraph from '@/components/LineGraph.vue'
import LineGraphFixedLength from '@/components/LineGraphFixedLength.vue'
import axios from 'axios'

import tmpData from '@/assets/testAll' //tmpData - devel

export default {
  props: {
    min: { type: Number, required: true, default: 0 },
    max: { type: Number, required: true, default: 380 },
    collData: {
      type: Array,
      default: null
    },
    activeCollection: {
      type: String,
      required: true,
      default: 'Test data view'
    },
    zoneHigh: {
      type: Number,
      required: true,
      default: 23.45
    },
    zoneLow: {
      type: Number,
      required: true,
      default: -13.5
    }
  },
  components: {
    // SimpleGraph,
    HeatmapGraph,
    LineGraph,
    LineGraphFixedLength
  },
  data() {
    return {
      tmpData: tmpData,
      parsed: [],
      heatPlotData: [],
      tempLine: [],
      lineChartSeries: [],
      singleLineChartSeries: [],
      tempChartSeries: [],
      highcharts,
      timestamps: [],
      rtTimer: 2000,
      rtChecked: false,
      lastData: {}
    }
  },
  mounted() {
    this.realTimer = setInterval(() => {
      if (this.rtChecked == true) {
        console.log('checked realtime')
        // this.updateChart()
        this.updateSingleSearies()
      }
      console.log('Tick')
    }, this.rtTimer)
  },
  beforeDestroy() {
    clearInterval(this.realTimer)
  },
  watch: {
    //Asi neni potreba, reload klicem
    collData: {
      immediate: true,
      deep: true,
      handler() {
        console.log('Changing data?')
        if (this.collData != null) {
          this.tmpData = this.collData
          console.log('collData' + this.tmpData)
        }
      }
    }
  },
  created() {
    // console.log('Created called')
    console.log('Colldata set tmpData:' + (this.collData != null))
    this.updateGraphs()
    // this.lineChartSeries.push(tmp)
    // )
    // Multi line graph - same as single, but more data, resp. series containg objects, with name and data
  },
  methods: {
    async updateSingleSearies() {
      var tmpData = {}
      console.log(
        'RT ' +
          this.$store.state.backendUrl +
          'getData?name=' +
          this.activeCollection +
          '&limit=1'
      )
      await axios
        .get(
          this.$store.state.backendUrl +
            'getData?name=' +
            this.activeCollection +
            '&limit=1'
        )
        .then(response => (tmpData = response.data))
      this.hgKey = 'dataReady'
      console.log('loadCollData returned ' + tmpData.length + ' ' + tmpData[0])
      this.singleLineChartSeries[0].name = new Date(
        tmpData[0].timestamp
      ).toLocaleString()
      this.singleLineChartSeries[0].data = tmpData[0].data
        .filter(zaznam => zaznam.length < this.max)
        .filter(zaznam => zaznam.length > this.min)
        .map(row => [row.length, row.temp])
      console.log('RT-gen ' + this.singleLineChartSeries[0].name)
    },
    updateGraphs() {
      this.parsed = this.tmpData.map((mereni, index) => {
        return {
          datetime: new Date(mereni.timestamp).toLocaleString(),
          rowData: mereni.data
            .filter(zaznam => zaznam.length < this.max)
            .filter(zaznam => zaznam.length > this.min)
            .map(zaznam => [zaznam.length, index, zaznam.temp])
        }
      })
      for (var i in this.tmpData) {
        this.timestamps.push(this.parsed[i].datetime)
      }
      for (var idx in this.parsed) {
        this.heatPlotData.push(...this.parsed[idx].rowData)
      }
      // this.heatPlotData = this.parsed.map(row => {
      //   return {
      //     name: row.datetime,
      //     data: row.rowData
      //   }
      // })
      // this.hdata.splice(0, 22)

      // line chart
      // this.hdata.splice(11, 22)
      this.lineChartSeries = this.parsed.map(row => {
        return {
          name: row.datetime,
          data: row.rowData.map(row => [row[0], row[2]])
        }
      })
      this.singleLineChartSeries.push(this.lineChartSeries[0])
      this.lastData = this.singleLineChartSeries.name
    }
  }
}
</script>

<style lang="scss" scoped>
.figure {
  border: 1px #cccccc solid;
  padding: 4px;
  width: 100%;
  height: 550px;
}
.h3 {
  padding-left: 0.5em;
}

// obsolete
#highChartsContainer {
  width: 100%;
  height: 400px;
  background: teal;
}
.custom-control-label {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}
</style>
