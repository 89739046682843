<template>
  <div id="app">
    <nav
      class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
    >
      <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="/"
        >KABEX visualizer</a
      >
    </nav>
    <div>
      <div class="container-fluid">
        <div class="row">
          <nav
            id="sidebarMenu"
            class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          >
            <div class="sidebar-sticky pt-3">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'about' }"
                    class="nav-link active feather feather-bar-chart"
                  >
                    <BaseIcon name="home" width="1em" height="1em" />Home
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'dashboard' }"
                    class="nav-link active"
                  >
                    <BaseIcon name="bar-chart-2" width="1em" height="1em" />DTS
                    - Temp.
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'dashboardDsts' }"
                    class="nav-link active"
                  >
                    <BaseIcon name="bar-chart-2" width="1em" height="1em" />DSTS
                    - Gain
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'dashDstsFreq' }"
                    class="nav-link active"
                  >
                    <BaseIcon name="bar-chart-2" width="1em" height="1em" />DSTS
                    - Freq.
                  </router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link
                    :to="{ name: 'dash-example' }"
                    class="nav-link active feather feather-bar-chart"
                  >
                    <BaseIcon name="bar-chart-2" width="1em" height="1em" />Dash
                    test site
                  </router-link>
                </li> -->
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'status' }"
                    class="nav-link active feather feather-bar-chart"
                  >
                    <BaseIcon name="tool" width="1em" height="1em" />Status
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
          <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <router-view />
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import './assets/scss/custom.scss';
#sidebarMenu {
  height: 100%;
}
</style>
