<template>
  <div>
    <h2>Gain variability</h2>
    <div class="d-flex flex-row">
      <div class="custom-control custom-switch">
        <input
          class="custom-control-input"
          type="checkbox"
          id="rtCheck"
          v-model="rtChecked"
        />
        <label class="custom-control-label" for="rtCheck"
          ><b>Realtime</b> - Update graphs every {{ rtTimer / 1000 }}s, if
          supported.</label
        >
      </div>
    </div>
    <div class="d-flex flex-row">
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            :plotData="singleLineChartSeries"
            graphIdName="lineChartSingle"
            graphTitle="Gain measurements"
            graphLegend="Gain [%]"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraph
            :highcharts="highcharts"
            :plotData="lineChartSeries"
            graphIdName="lineChartMulti"
            graphTitle="Gain measurements"
            graphLegend="Gain [%]"
          >
            Multi-measurements graph
          </LineGraph>
        </figure>
      </div>
    </div>
    <div class="d-flex flex-row">
      <!-- <h3>RealDataTest</h3> -->
      <div class="col-md">
        <figure class="figure">
          <HeatmapDsts
            :highcharts="highcharts"
            :plotData="heatPlotData"
            :timestamps="timestamps"
            graphIdName="heatmapGraphReal"
            graphTitle="Gain progression"
            wrapperTitle="Heatmap of gain over distance"
            graphLegend="Gain [%]"
          />
        </figure>
      </div>
      <!-- <h3>LineChartWrap</h3> -->
      <div class="col-md">
        <figure class="figure">
          <LineGraphFixedDsts
            :highcharts="highcharts"
            :plotData="singleLineChartSeries"
            :activeCollection="activeCollection"
            graphIdName="lineChartFixedLength"
            graphTitle="Gain progression"
            :maxDistance="maxFixDistanceDsts"
          />
        </figure>
      </div>

      <!-- <h3>HM</h3> -->
      <!-- <div class="col-md">
        <figure class="figure">
          <HeatmapGraph
            :highcharts="highcharts"
            graphIdName="heatmapGraph"
            :plotData="hdata"
          />
        </figure>
      </div> -->
    </div>
  </div>
</template>

<script>
import highcharts from 'highcharts'
import hdata from 'highcharts/modules/data'
import heatmap from 'highcharts/modules/heatmap'
import highchartsMore from 'highcharts/highcharts-more'
import hexporting from 'highcharts/modules/exporting'
import hboost from 'highcharts/modules/boost'
import hexportData from 'highcharts/modules/export-data'
hdata(highcharts)
heatmap(highcharts)
highchartsMore(highcharts)
hexporting(highcharts)
hboost(highcharts)
hexportData(highcharts)
// import SimpleGraph from '@/components/SimpleGraph.vue'
import HeatmapDsts from '@/components/HeatmapDsts.vue'
import LineGraph from '@/components/LineGraph.vue'
import LineGraphFixedDsts from '@/components/LineGraphFixedDsts.vue'
import axios from 'axios'

// import tmpData from '@/assets/testAll' //tmpData - devel

export default {
  props: {
    min: { type: Number, required: true, default: 0 },
    max: { type: Number, required: true, default: 1000 },
    collData: {
      type: Array,
      default: null
    },
    activeCollection: {
      type: String,
      required: true,
      default: 'Test data view'
    }
  },
  components: {
    HeatmapDsts,
    LineGraph,
    LineGraphFixedDsts
  },
  data() {
    return {
      // tmpData: tmpData,
      tmpData: [],
      parsed: [],
      heatPlotData: [],
      tempLine: [],
      lineChartSeries: [],
      singleLineChartSeries: [],
      tempChartSeries: [],
      highcharts,
      //Mock data for development
      hdata: [
        {
          x: 0.0,
          y: 1,
          value: 15.0
        },
        {
          x: 0.3,
          y: 1,
          value: 15.2
        },
        {
          x: 0.6,
          y: 1,
          value: 15.5
        },
        {
          x: 0.9,
          y: 1,
          value: 15.7
        },
        {
          x: 1.2,
          y: 1,
          value: 17.3
        },
        {
          x: 1.5,
          y: 1,
          value: 26.0
        },
        {
          x: 1.8,
          y: 1,
          value: 37.0
        },
        {
          x: 2.1,
          y: 1,
          value: 29.8
        },
        {
          x: 2.4,
          y: 1,
          value: 24.3
        },
        {
          x: 2.7,
          y: 1,
          value: 21.0
        },
        {
          x: 3.0,
          y: 1,
          value: 18.0
        },
        {
          x: 0.0,
          y: 2,
          value: 5.0
        },
        {
          x: 0.3,
          y: 2,
          value: 6.2
        },
        {
          x: 0.6,
          y: 2,
          value: 7.5
        },
        {
          x: 0.9,
          y: 2,
          value: 8.7
        },
        {
          x: 1.2,
          y: 2,
          value: 9.3
        },
        {
          x: 1.5,
          y: 2,
          value: 15.0
        },
        {
          x: 1.8,
          y: 2,
          value: 25.0
        },
        {
          x: 2.1,
          y: 2,
          value: 18.8
        },
        {
          x: 2.4,
          y: 2,
          value: 13.3
        },
        {
          x: 2.7,
          y: 2,
          value: 7.0
        },
        {
          x: 3.0,
          y: 2,
          value: -2.0
        },
        {
          x: 0.0,
          y: 3,
          value: -5.0
        },
        {
          x: 0.3,
          y: 3,
          value: -3.2
        },
        {
          x: 0.6,
          y: 3,
          value: -2.5
        },
        {
          x: 0.9,
          y: 3,
          value: -1.3
        },
        {
          x: 1.2,
          y: 3,
          value: -0.7
        },
        {
          x: 1.5,
          y: 3,
          value: 5.0
        },
        {
          x: 1.8,
          y: 3,
          value: 15.0
        },
        {
          x: 2.1,
          y: 3,
          value: 8.8
        },
        {
          x: 2.4,
          y: 3,
          value: 3.3
        },
        {
          x: 2.7,
          y: 3,
          value: -3.0
        },
        {
          x: 3.0,
          y: 3,
          value: -12.0
        }
      ],
      timestamps: [],
      rtTimer: 2000,
      rtChecked: false,
      lastData: {},
      maxFixDistanceDsts: 800
    }
  },
  created() {
    // console.log('Created called')
    // console.log('Colldata set:' + JSON.stringify(this.collData))
    if (this.collData != null) {
      this.tmpData = this.collData
    }
    // console.log('Tmpdata - parsed set:' + JSON.stringify(this.tmpData))
    var start = Date.now()
    this.updateGraphs()
    var end = Date.now()
    console.log('Time to update ' + (end - start))
    // this.lineChartSeries.push(tmp)
    // )
    // Multi line graph - same as single, but more data, resp. series containg objects, with name and data
  },
  mounted() {
    this.realTimer = setInterval(() => {
      if (this.rtChecked == true) {
        console.log('checked realtime')
        // this.updateChart()
        this.updateSingleSearies()
      }
      console.log('Tick')
    }, this.rtTimer)
  },
  beforeDestroy() {
    clearInterval(this.realTimer)
  },
  methods: {
    async updateSingleSearies() {
      var tmpData = {}
      this.lastData = this.singleLineChartSeries[0].name
      console.log(
        'RT ' +
          this.$store.state.backendUrl +
          'getDstsData?name=' +
          this.activeCollection +
          '&limit=1'
      )
      await axios
        .get(
          this.$store.state.backendUrl +
            'getDstsData?name=' +
            this.activeCollection +
            '&limit=1'
        )
        .then(response => (tmpData = response.data))
      this.hgKey = 'dataReady'
      console.log('loadCollData returned ' + tmpData.length + ' ' + tmpData[0])
      this.singleLineChartSeries[0].name = new Date(
        tmpData[0].timestamp
      ).toLocaleString()
      console.log(
        'RT lastData' +
          this.lastData +
          ', new date ' +
          new Date(tmpData[0].timestamp).toLocaleString()
      )
      if (this.lastData == new Date(tmpData[0].timestamp).toLocaleString()) {
        console.log('RT - same data, nothing to do')
        return
      }
      this.timestamps[0] = this.singleLineChartSeries[0].name
      this.singleLineChartSeries[0].data = tmpData[0].data
        .filter(zaznam => zaznam.length < this.max)
        .filter(zaznam => zaznam.length > this.min)
        .map(row => [row.length, row.gain])
      // Fix heatplotdata
      this.parsed[0].data = tmpData[0].data.map(row => [
        row.length,
        0,
        row.gain
      ])
      this.heatPlotData = []
      for (var idx in this.parsed) {
        this.heatPlotData.push(...this.parsed[idx].data)
      }
      console.log('RT-gen ' + this.singleLineChartSeries[0].name)
    },
    updateGraphs() {
      this.parsed = this.tmpData.map((mereni, index) => {
        return {
          name: new Date(mereni.timestamp).toLocaleString(),
          data: mereni.data
            .filter(zaznam => zaznam.length < this.max)
            .filter(zaznam => zaznam.length > this.min)
            .map(zaznam => [zaznam.length, index, zaznam.gain])
        }
      })
      for (var i in this.tmpData) {
        this.timestamps.push(this.parsed[i].name)
      }
      for (var idx in this.parsed) {
        this.heatPlotData.push(...this.parsed[idx].data)
      }
      // this.hdata.splice(0, 22)

      // line chart
      this.hdata.splice(11, 22)
      this.lineChartSeries = this.parsed.map(row => {
        return {
          name: row.name,
          data: row.data.map(row => [row[0], row[2]]),
          zIndex: 20 - row.data[0][1],
          legendIndex: 20 - row.data[0][1]
        }
      })
      this.singleLineChartSeries.push(this.lineChartSeries[0])
    }
  }
}
</script>

<style lang="scss" scoped>
.figure {
  border: 1px #cccccc solid;
  padding: 4px;
  width: 100%;
  height: 550px;
}
.h3 {
  padding-left: 0.5em;
}

#highChartsContainer {
  width: 100%;
  height: 400px;
  background: teal;
}
.custom-control-label {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}
.highcharts-credits {
  display: none;
}
</style>
