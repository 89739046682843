import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    backendUrl: 'nic',
    appVersion: '1.1.0'
  },
  mutations: {
    setBackendUrl(state, ip) {
      state.backendUrl = 'http://' + ip + ':8084/'
    }
  },
  actions: {
    setBackUrl(context) {
      if (localStorage.backendIp) {
        context.commit('setBackendUrl', localStorage.backendIp)
      } else {
        context.commit('setBackendUrl', 'localhost')
      }
    }
  },
  modules: {}
})
