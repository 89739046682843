<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Dashboard</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div>
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary dropdown-toggle"
          >
            <span data-feather="calendar"></span>
            Last data
          </button>
        </div>
        <div>
          <b-button v-b-modal.modal-settings variant="link"
            ><BaseIcon name="settings" width="1.5em" height="1.5em"
          /></b-button>

          <b-modal id="modal-settings" title="DTS graph settings">
            <p class="my-4">Inner body</p>
          </b-modal>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>

    <div class="alert alert-primary" role="alert">
      A simple primary alert—check it out!
      <span class="badge badge-secondary">New</span>
      <span class="badge badge-primary">New</span>
    </div>

    <div class="card" style="width: 49%;">
      <img src class="card-img-top" alt="temp" />
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>

    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <svg
      class="bi bi-chevron-right"
      width="32"
      height="32"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
      />
    </svg>
    <!-- <svg class="bi" width="32" height="32" fill="currentColor">
    <use xlink:href="@/assets/bootstrap-icons.svg#" />
    </svg>-->

    <!-- <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas> -->
    <h4>Icons - feather</h4>
    <p>
      <br />I tag:
      <i data-feather="circle"></i>
      <br />Using a baseicon.vue file:
      <BaseIcon name="activity" />
      <BaseIcon name="circle" />
    </p>
    <div class="alert alert-primary" role="alert">
      A simple primary alert—check it out!
    </div>
    <button type="button" class="btn btn-primary">Primary</button>
    <h4>Icons - Bootstrap</h4>
    <p>
      <i data-feather="circle"></i>
      img src
      <img
        src="/node_modules/bootstrap-icons/bootstrap-icons.svg#Slash"
        alt="Boot"
        width="36"
        height="36"
      />asf
    </p>
    <h3>Drodowns</h3>
    <div>
      <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2">
        <b-dropdown-item>First Action</b-dropdown-item>
        <b-dropdown-item>Second Action</b-dropdown-item>
        <b-dropdown-item>Third Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item active>Active action</b-dropdown-item>
        <b-dropdown-item disabled>Disabled action</b-dropdown-item>
      </b-dropdown>
    </div>
    <!--  -->
    <hr />
    <h3>Highcharts</h3>
    <HWexample />
    <!-- <HighchartsWrapper /> -->
    <!-- Separator -->
    <!-- <h3>Timestamps</h3>
    Loaded following items:
    <div v-for="item in tmpData" :key="item._id">
      {{ item._id }} has timestamp: {{ item.timestamp }} and
      {{ item.data.length }} items.
    </div> -->
    <hr />
    <h2>Range selector</h2>
    <label for="customRange3" class="form-label">Example range</label>
    <input
      type="range"
      class="form-range"
      min="0"
      max="400"
      step="0.5"
      length="300px"
      id="customRange3"
      v-model="targetDistance"
      @mouseup="testik += 1"
    />
    Value from range: <strong> {{ targetDistance }}m </strong>, mouseup:
    {{ testik }}
    <button>Update graph</button>
    <!-- @click="updateChart" -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HWexample from '@/components/HWexample.vue'

export default {
  props: {
    targetDistance: {
      type: Number,
      required: true,
      default: 100.3
    },
    testik: {
      type: Number,
      required: true,
      default: 0
    }
  },
  components: {
    HWexample
  }
}
</script>

<style>
.modal-backdrop {
  opacity: 0.5 !important;
}
.btn-link {
  color: #212529;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  .nav-link {
    color: gray;
  }
}
</style>
